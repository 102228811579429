@import 'styles/core/typography';
@import 'styles/utils/mixins';
@import 'styles/core/buttons';

.container {
  display: flex;
  flex-direction: row;
  gap: var(--space-size-1);
  background-color: var(--color-bg);
  padding: var(--space-size-1) var(--space-size-1-5);

  div {
    display: flex;

    &:first-child {
      align-items: center;
      gap: var(--space-size-1);
      flex: 1 1 auto;
    }
  }

  .logo {
    border-radius: var(--space-size-2);
    border: var(--divider-primary);
  }

  .closeIcon {
    padding: var(--space-size-1);

    svg {
      width: var(--space-size-1-5);
      height: var(--space-size-1-5);
    }
  }

  .title {
    display: flex;
    flex-direction: column;

    span:first-child {
      @include label-m-bold;
    }

    span:last-child {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      @include body-xs-medium;
      color: var(--color-on-bg-secondary);
    }
  }

  .downloadButton {
    @include body-bold;
    text-transform: uppercase;
    color: var(--color-primary);
    cursor: pointer;
  }

  &.hidden {
    display: none;
    visibility: hidden;
    z-index: -1;
  }
}
